<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant required type="edit" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="작업기간"
            name="workDts"
            v-model="searchParam.workDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="dangerFlagItems"
            itemText="codeName"
            itemValue="code"
            label="위험작업여부"
            name="dangerFlag"
            v-model="searchParam.dangerFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="사용여부"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-card title="작업현황" class="cardClassDetailForm" :noMarginPadding="true">
      <template slot="card-button">
        <q-btn-group v-if="isPlant" outline>
          <c-btn label="추가" v-if="editable && data.maps.length > 0" icon="add" @btnClicked="addClick" />
          <c-btn label="삭제" v-if="editable && tempMapDetails.length > 0" icon="remove" @btnClicked="removeClick"/>
          <c-btn 
            v-if="editable && data.maps.length > 0" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="data.maps"
            mappingType="POST"
            label="저장" 
            icon="save" 
            @beforeAction="saveClick"
            @btnCallback="saveCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
        <q-btn-group v-else outline>
          <font style="font-size:0.8em;font-weight:300;" class="blinking" color="#C10015">
            ※ 공사현장관리 메뉴에서 공사현장을 추가하세요.
          </font>
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" ref="carousel">
            <q-carousel
              v-if="data.maps && data.maps.length > 0"
              class="map-carousel"
              control-type="regular"
              control-color="grey-6"
              arrows
              animated
              v-model="data.slide"
              infinite
              @transition="transition"
            >
              <q-carousel-slide 
                v-for="(map, idx) in data.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc"
                >
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="q-img__content absolute-full"> 
                      <!-- @resizing="(x, y, w, h) => onResize(data, x, y, w, h)" -->
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :resizable="false"
                      :parent="true"
                      :draggable="editable"
                      :x="item.locationX"
                      :y="item.locationY"
                      :w="30" 
                      :h="30"
                      :grid="[30, 30]"
                      @dragging="(x, y) => onDrag(item, x, y)"
                    >
                      <!-- {{idx + 1}} -->
                      <q-img :src="require('@/assets/images/worker.jpg')" fit="scale-down"
                        style="bottom: 5px;" :contain="true">
                      </q-img>
                      <div class="markImage-abbreviation-parent"
                        :style="{ 
                          width: '220px', 
                          top: getTop(item),
                          left: getLeft(item),
                        }">
                        <div class="markImage-abbreviation" 
                          @mouseover="() => { item.isHover = true }"
                          @mouseleave="() => { item.isHover = false }">
                          <transition name="mark-list" tag="div">
                            <div>
                              <q-banner
                                dense 
                                :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                <template v-slot:avatar>
                                  <q-icon v-if="item.dangerFlag === 'Y'" name="warning" class="text-red blinking" size='xs' >
                                    <q-tooltip anchor="bottom left" self="top left">
                                      <div class="tooltipCustomTop">
                                        {{ '위험작업' }}
                                      </div>
                                    </q-tooltip>
                                  </q-icon>
                                  <q-icon v-else name="warning_amber" class="text-red" size='xs' >
                                  </q-icon>
                                </template>
                                <div class="caption-header">
                                  {{'작업명: ' + item.workName}}
                                  <q-btn flat style="font-size:5px;" color="red" icon="close" size='xs' @click="bannerClick(item.sopWorkLocationId)">
                                    <q-tooltip anchor="bottom left" self="top left">
                                      <div class="tooltipCustomTop">
                                        {{ '삭제하기' }}
                                      </div>
                                    </q-tooltip>
                                  </q-btn>
                                </div>
                                <!-- <q-btn class="q-ml-sm" outline round color="red" size="9px" label="" icon="add"/> -->
                                <div class="caption-work">
                                  {{ '기간: ' + item.workStartDt + '~' + item.workEndDt}}
                                  <br>
                                </div>
                              </q-banner>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </VueDraggableResizable>
                  </div>
                  
                </template>
              </q-carousel-slide>
            </q-carousel>
            <el-empty v-else 
              :image-size="435"
              description="지도가 없습니다."></el-empty>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container">
            <c-table
              ref="table"
              title="작업현황"
              :isTitle="false"
              selection="multiple"
              :columns="grid.columns"
              :data="tempMapDetails"
              rowKey="sopWorkLocationId"
            >
            </c-table>
          </div>
        </template>
      </template>
    </c-card>
    
    <c-dialog :param="popupOptions"></c-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'work-lcation-manage',
  components: { VueDraggableResizable },
  props: {
  },
  data() {
    return {
      searchParam: {
        companyCd: '',
        plantCd: null,
        useFlag: 'Y',
        workDts: [],
        dangerFlag: null,
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      dangerFlagItems: [ // 위험작업여부
        { code: 'Y', codeName: '위험작업' },
        { code: 'N', codeName: '일반작업' },
      ],
      mapMessage: '',
      mapSrc: require('@/assets/images/no-image.png'),
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'workName',
            field: 'workName',
            label: '작업명',
            align: 'left',
            type: "text",
            sortable: true,
          },
          {
            name: 'workDt',
            field: 'workDt',
            label: '작업기간',
            style: 'width:200px',
            type: 'date',
            range: true,
            align: 'center',
            sortable: true,
          },
          {
            name: 'vendorCd',
            field: 'vendorCd',
            label: '작업업체',
            align: 'center',
            style: 'width:200px',
            type: 'vendor',
            sortable: true,
          },
          {
            name: 'workers',
            field: 'workers',
            label: '작업<br>인원',
            align: 'right',
            type: 'number',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'dangerFlag',
            field: 'dangerFlag',
            label: '위험<br>작업',
            align: 'center',
            style: 'width:60px',
            sortable: true,
            type: 'check',
            true: 'Y',
            false: 'N',
          },
        ],
        data: [],
        height: '500px'
      },
      data: {
        slide: 0,
        mapSrc: require('@/assets/images/no-image.png'),
        fullscreen: false,
        maps: [],
      },
      tempMapDetails: [],
      listUrl: '',
      saveUrl: '',
      isSave: false,
      deleteUrl: '',
      deletesUrl: '',
      ratio: 0,
      sopMapId: '',
      isPlant: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 1.1
    },
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.listUrl = selectConfig.sop.work.loc.list.url;
      this.saveUrl = transactionConfig.sop.work.loc.insert.url;
      this.deletesUrl = transactionConfig.sop.work.loc.deletes.url;
      this.deleteUrl = transactionConfig.sop.work.loc.delete.url;
      
      if (!this.$store.getters.user.plantCds) {
        this.isPlant = false;
      }
      this.getList();
    },
    getList() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      if (!this.searchParam.plantCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공사현장을 선택하세요.', // 공사현장을 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.data.maps = _result.data
        if (this.data.maps && this.data.maps.length > 0) {
          this.tempMapDetails = this.data.maps[this.data.slide].maps;

          this.$_.forEach(this.tempMapDetails, _item => {
            if (_item.workStartDt && _item.workEndDt) {
              _item.workDt = [_item.workStartDt, _item.workEndDt]
            }
          })

          console.log(this.data.maps[0])
          this.$refs['carousel'].style.setProperty("--map-ratio", this.data.maps[0].ratio);
        } else {
          this.tempMapDetails = [];
        }
      },);
    },
    transition() {   
      this.tempMapDetails = this.data.maps[this.data.slide].maps;
      this.$_.forEach(this.tempMapDetails, _item => {
        if (_item.workStartDt && _item.workEndDt) {
          _item.workDt = [_item.workStartDt, _item.workEndDt]
        }
      })
      this.ratio = this.data.maps[this.data.slide].ratio
      this.sopMapId = this.data.maps[this.data.slide].sopMapId
    },
    onDrag(item, x, y) {
      this.realKey = item.sopWorkLocationId
      this.$_.forEach(this.data.maps[this.data.slide].maps, _item => {
        if (_item.sopWorkLocationId === item.sopWorkLocationId) {
          if (_item.editFlag !== 'C') {
            _item.editFlag = 'U'
            _item.chgUserId = this.$store.getters.user.userId
          }
        }
      })
      this.$set(item, 'locationX', x)
      this.$set(item, 'locationY', y)
    },
    onResize(item, x, y, w, h) {
      this.$_.forEach(this.data.maps[this.data.slide].maps, _item => {
        if (_item.sopWorkLocationId === item.sopWorkLocationId) {
          if (_item.editFlag !== 'C') {
            _item.editFlag = 'U'
            _item.chgUserId = this.$store.getters.user.userId
          }
        }
      })
      this.$set(item, 'locationX', x)
      this.$set(item, 'locationY', y)
      this.$set(item, 'locationW', w)
      this.$set(item, 'locationH', h)
      this.$set(item, 'chgUserId', this.$store.getters.user.userId)
    },
    getWidth() {
      let width = 0;
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationX + 80;
      let detailLocation = item.locationX + 80 
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 29;
      let calLocation = item.locationY;
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return top + 'px'
    },
    addClick() {
      this.data.maps[this.data.slide].maps.push({
        sopWorkLocationId: uid(),
        companyCd: this.$store.getters.user.companyCd,
        plantCd: this.data.maps[this.data.slide].plantCd,
        workName: '',
        workDt: [],
        workStartDt: '',
        workEndDt: '',
        vendorCd: '',
        workers: 0,
        dangerFlag: 'N',
        sopMapId: this.data.maps[this.data.slide].sopMapId,
        locationX: 0,
        locationY: 0,
        locationW: 0,
        locationH: 0,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
  
    getMapSrc(type) {
      console.log(type)
    },
    saveClick() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.data.maps, item => {
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;

            this.$_.forEach(item.maps, _item => {
              if (_item.workDt && _item.workDt.length > 0) {
                _item.workStartDt = _item.workDt[0];
                _item.workEndDt = _item.workDt[1];
              }
            })
          });
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    bannerClick(id) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, id);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.data.maps[this.data.slide].maps = this.$_.reject(this.data.maps[this.data.slide].maps, {sopWorkLocationId: id})
            this.tempMapDetails = this.data.maps[this.data.slide].maps;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    removeClick() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deletesUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            }
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                this.$_.forEach(this.data.maps, _item => {
                  _item.maps = this.$_.reject(_item.maps, {sopWorkLocationId: item.sopWorkLocationId})
                })
              })
              this.tempMapDetails = this.data.maps[this.data.slide].maps;
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important
.q-img__content > div
    background: rgba(0,0,0,0) 
.vdr 
  border: 3px dashed #000

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  // height: 30px !important
  position: static
  padding: 16px
  color: blue
  // background: rgba(0,0,0,0)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      // cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        i
          font-size: 25px
      .markImage-abbreviation-banner.last-banner
        border-right: 0
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        padding: 1px !important
.caption-work
  font-size: 0.5em
  font-style: italic
  white-space: normal
  color: #555

.caption-header
  font-size: 0.75em
  font-style: italic
  white-space: normal
  color: #555

.map-carousel
  height: var(--map-height) !important
  .q-carousel__slide
    width: 800px !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

</style>

<style scoped>

.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>