import { render, staticRenderFns } from "./workLocationLayout.vue?vue&type=template&id=971bb184&scoped=true&"
import script from "./workLocationLayout.vue?vue&type=script&lang=js&"
export * from "./workLocationLayout.vue?vue&type=script&lang=js&"
import style0 from "./workLocationLayout.vue?vue&type=style&index=0&id=971bb184&lang=sass&"
import style1 from "./workLocationLayout.vue?vue&type=style&index=1&id=971bb184&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "971bb184",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\PSM-PROJECT\\SAFETY-DOUMI\\andamiSafetyDoumi\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('971bb184')) {
      api.createRecord('971bb184', component.options)
    } else {
      api.reload('971bb184', component.options)
    }
    module.hot.accept("./workLocationLayout.vue?vue&type=template&id=971bb184&scoped=true&", function () {
      api.rerender('971bb184', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sop/loc/workLocationLayout.vue"
export default component.exports